:root {
  user-select: none;
}

header {
  background-color: rgb(24, 23, 23);
  text-align: center;
}

body {
  background-color: #181717;
  text-align: center;
}

.collagePieceImage {
  text-indent: -10000px;
  /*border: calc(var(--image-size) / 200);
  border-style: solid;
  border-color: black;*/
}

.nowShowing {
  background-color: #222222;
  color: white;
  position: fixed;
  top: 10px;
  left: 50%;
  padding: 5px;
  padding-right: 20px;
  padding-left: 20px;
  display: block;
  flex-direction: column;
  /*width: 100%;*/
  justify-content: center;
  z-index: 3;
  transform: translateX(-50%);
  font-size: 10px;
  border-radius: 30px;
}

.nowShowing > button {
  background-color: #333333;
  color: white;
  border-radius: 5px;
  border-width: 0px;
  margin-top: 7px;
  font-size: 10px;
}

.successPopUp {
  background-color: #222222;
  color: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  padding: 20px;
  padding-right: 30px;
  padding-left: 30px;
  width: min(70%, 320px);
  z-index: 3;
  transform: translate(-50%, -50%);
  font-size: 10px;
  border-radius: 30px;
}

@keyframes fade-no-delay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}
@keyframes fade-slight-delay {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes fade-long-delay {
  0% {
    opacity: 0;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

.fade-in-no-delay {
  animation: fade-no-delay 0.3s ease-in;
}
.fade-in-slight-delay {
  animation: fade-slight-delay 0.6s ease-in;
}

.fade-in-long-delay {
  animation: fade-long-delay 0.9s ease-in;
}

.welcomeScreen {
  background-color: #181717;
  opacity: 96%;
  position: fixed;
  color: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: calc(100% - 40px);
  min-height: 200%;
  top: 0px;
  left: 0px;
  padding: 20px;
  padding-right: 20px;
  padding-left: 20px;
  /*width: min(70%, 300px);*/
  z-index: 3;
  /*transform: translate(-50%, -50%);*/
  /*font-size: 10px;
  /*border-radius: 30px;*/
  text-align: left;
}
.landingPage {
  background-color: rgba(24, 24, 24, 0.8); /*#181717;*/
  opacity: 100%;
  position: fixed;
  color: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(40%);
  min-height: 100%;
  top: 0px;
  left: 0px;
  padding: 20px;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: 0px;
  padding-bottom: 30px;
  /*width: min(70%, 300px);*/
  z-index: 3;
  /*transform: translate(-50%, -50%);*/
  /*font-size: 10px;
  /*border-radius: 30px;*/
  text-align: left;
  /*border-radius: 50px;*/
}

.collageContainer {
  --image-size: 70px;
  margin: auto;
  margin-top: calc(90px - (var(--image-size) * calc(30 / 100)));
  margin-bottom: max(calc(200px - var(--image-size)), 100px);
  /*margin-right: 100px;*/
  width: fit-content;
  padding: calc(var(--image-size) / 2);
  padding-top: calc(var(--image-size) / 2);
  padding-bottom: calc(var(--image-size) / 3);
  /*padding-bottom: calc(var(--image-size) * calc(60 / 100));*/
  background-color: black;
  /*border: 1px solid green;*/
  position: relative;
}

.authorizeContainer {
  /*position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0);*/
  height: 85vh;
  justify-content: space-around;
  z-index: 6;
}

.collage {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.collagePiece {
  background-color: black;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  width: fit-content;
  padding: calc(var(--image-size) / 12);
  padding-bottom: calc(var(--image-size) / 20);
  margin: calc(var(--image-size) / 12);
  margin-left: calc(var(--image-size) / 8);
  margin-right: calc(var(--image-size) / 8);
  text-align: center;
  border-radius: calc(var(--image-size) / 25);
}

.collagePieceImageContainer {
  position: relative;
  height: var(--image-size);
  width: var(--image-size);
  min-height: var(--image-size);
  min-width: var(--image-size);
  background-color: gray;

  overflow: hidden;
}

.collagePieceImage {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
/*this will need to account for long album names and artist names*/
.collagePieceFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  margin-top: calc(var(--image-size) / 9);
  height: auto;

  position: relative;
  width: 100%;
  height: calc(var(--image-size) / 8);
}

.collagePieceFooterTextContainer {
  max-width: 80%;
  width: 80%;
  height: 100%;
  position: absolute;
}

.collagePieceFooterText {
  /* Need this to position texts in the bottom left hand corner*/
  letter-spacing: 0.01px;
  font-size: calc(var(--image-size) / 15);
  position: absolute;
  bottom: 0;
  left: 0;
}

.qrCode {
  position: absolute;
  width: calc(var(--image-size) / 5.5);
  height: calc(var(--image-size) / 5.5);
  right: 0;
  bottom: 0;
  padding-top: 1.5px;
  padding-left: 2px;
}

.qrCode img {
  position: absolute;
  height: calc(var(--image-size) / 5.5);
  margin: 0px;
}

.largeQrCode {
  position: absolute;
  width: calc(var(--image-size) / 3);
  height: calc(var(--image-size) / 3);
  right: 0;
  bottom: 0;
  padding-top: 2px;
  padding-left: 2px;
}

.largeQrCode img {
  position: absolute;
  height: calc(var(--image-size) / 3);
  margin: 0px;
}

.posterFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  margin-top: calc(var(--image-size) / 20);
  height: auto;

  position: relative;
  width: auto;
  height: calc(var(--image-size) / 3);
  margin-left: calc(var(--image-size) / 10);
  margin-right: calc(var(--image-size) / 10);
}

.posterFooterTextContainer {
  max-width: 80%;
  width: 80%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: end;
}

.posterFooterText {
  /* Need this to position texts in the bottom left hand corner*/
  letter-spacing: 0.01px;
  font-size: calc(var(--image-size) / 5);
  position: relative;

  bottom: 0;
  left: 0;
}
