.menuContainer {
  /*touch-action: none;
  /*height: 18px;*/
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #222222; /*#1db954;*/
  min-height: 100vh;
  transform: translateX(-100%);
  width: min(400px, calc(80vw)); /* - 42px));
  /*width: min(400px, 100vw);*/
  z-index: 3;
  /*box-shadow: -10px -10px 10px rgb(24, 23, 23);*/
  /*padding: 10px;
  /*margin: 10px;*/
  border-radius: 10px;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  transition: 0.5s;
  overflow-x: hidden;
}

.zoomShuffleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 2.5vw;
  right: 2.5vw;
  background-color: #222222;
  padding: 5px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 30px;
}

.zoomButton {
  /*position: fixed;
  top: 5vh;
  right: 2.5vw;*/
  background-color: #333333;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  z-index: 2;
  margin-bottom: 5px;
  margin-top: 5px;
  border-width: 0px;
  border-color: white;
}

.zoomButton > img {
  height: 30px;
}

.zoomButton:hover {
  cursor: pointer;
}

.shuffleButton {
  background-color: #333333;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  z-index: 2;
  border-width: 0px;
  border-color: white;
  border-style: solid;
  margin-bottom: 5px;
  margin-top: 5px;
  z-index: 2;
}

.shuffleButton:hover {
  cursor: pointer;
}

.shuffleButton > img {
  padding-top: 5px;
  height: 30px;
}

.qrCodeButton {
  background-color: #333333;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  z-index: 2;
  border-width: 0px;
  border-color: white;
  border-style: solid;
  margin-bottom: 5px;
  margin-top: 5px;
  z-index: 2;
}

.qrCodeButton:hover {
  cursor: pointer;
}

.qrCodeButton > img {
  padding-top: 3px;
  height: 26px;
}

.menuButton {
  background-color: #333333;
  height: 70px;
  width: 70px;
  border-radius: 35px;
  z-index: 2;
  margin-bottom: 20px;
  border-width: 0px;

  z-index: 2;
  position: fixed;
  bottom: 2.5vw;
  left: 2.5vw;

  z-index: 6;
}

.menuButton > img {
  padding-top: 3px;
  height: 40px;
}
.menuButton:hover {
  cursor: pointer;
}

.startOrderButton {
  position: absolute;

  background: none;

  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  color: white;
  background-color: green;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  position: fixed;
  bottom: 2.5vh;
  right: 2.5vw;
  border: none;
  border-radius: 30px;
  border-style: solid;
  border-color: white;
}

.closeMenuButton {
  position: absolute;
  bottom: 3vh;
  right: 3vh;
  background: none;
  color: white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.closeMenuButton > img {
  height: 20px;
}

/*.menuOptions {
  width: 80%;
}*/

.selectionButtons {
  /*width: 270px;*/
  /*display: flex;
  justify-content: space-around;*/
  /*position: relative;
  top: 10%;
  right: 10%;*/
  width: 90%;

  color: green;
  border-radius: 15px;

  background-color: white;
  transform: translateX(5%);
}

.selectionButton {
  padding: 5px;
  width: 33.33%;
  color: black;
  border-radius: 10px;
  border-width: 0px;
  font-size: min(3vw, 16px);
}
.selectionButton:hover {
  cursor: pointer;
}

.selectionText {
  color: white;
  padding-top: 3px;
}

.selectionButtonContainer {
  height: 100px;
  width: min(320px, calc(64vw));
  margin-bottom: 50px;
  font-size: min(2.5vw, 12px);
}

/*.variableSection {
  display: flex;
  flex-direction: column;
  width: 80%;
  border-radius: 5px;
  margin-top: 30px;
}*/
