button:hover {
  background-color: #222;
  cursor: pointer;
}
button:active {
  background-color: #222;
}

#samplePoster:hover {
  opacity: 80%;
  cursor: pointer;
}

button {
  background-color: #111;
}
