#card-element {
  position: fixed;
  z-index: 100;
  top: 10px;
  left: 10px;
}

.loadingAnimation {
  background-color: white;
  height: 30px;
  width: 30px;
  padding: 0px;
  margin-top: 50px;
  margin-left: 50%;
  transform: translateX(-50%);
  border-radius: 100px;
  animation: small-big 1s 0s 200;
}

@keyframes small-big {
  0% {
    padding: 0px;
    margin-top: 50px;
  }
  50% {
    padding: 5px;
    margin-top: 45px;
  }
  100% {
    padding: 0px;
    margin-top: 50px;
  }
}

a {
  color: white;
}
a:hover {
  color: darkgreen;
  cursor: pointer;
}
